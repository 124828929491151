// oce: Other Contact Errand
import React from "react";
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
	emptyArray
	, emptyObject
	, ACQ_ERD_OTHER
	, ACQ_ERD_SELECTED_BOTTOM
	, ACQ_ERD_MY
	, ACQ_ERD_LINKED
	, ACQ_ERD_SELECTED_TOP
	, CTX_REVIEW
	, CTX_SEARCH
	, D_BASIC_ERRANDS
	, RPLY_ERRAND
	, SEL_ACT_ACQ_ERD_OTHER
	, SEL_ACT_ACQ_SELECT
	, SEL_ACT_OTHER_CONTACT
	, SEL_ACT_MY_ERD
	, SEL_ACT_LINKED_ERD
} from '../common/v5/constants';
import { doNothing } from '../reactcomponents/common';
import ErrandBoxHeaderInfo from '../components/v5/ErrandBoxHeaderInfo';
import withOtherContactErrand from '../components/v5/OtherContactErrand';
import {
	withErrandBoxHeader
	, withHeaderLeftBox
	, withUserInfo
} from '../components/v5/ErrandBoxHeader';
import { mobileStateSelector } from '../redux/selectors/mobile';
import {
	contextMemo,
	messageTruncateByDefault,
	workflowSettingsSelector
} from '../redux/selectors/workflow';
import {
	acquireButtonsSelector
	, acquireErrandsSelector
	, canAcquireOtherContactErrandSelector
	, currentAcquireTabSelector
	, getCurrentErrand
	, getHistoriesAndMy
	, getMemoizeAssociatedObject
	, getMyId
	, historyContactsSelector
	, otherContactBasicErrandSelector
	, otherContactErrandAcquireDataSelector
	, otherContactErrandAttachmentSizeSelector
	, otherContactErrandClientSelector
	, otherContactErrandHistorySelector
	, otherContactErrandHistoryAgentsSelector
	, otherContactErrandIncomingAttachmentSizeSelector
	, otherContactErrandOwnerSelector
	, otherContactErrandSelector
	, otherContactErrandTagsSelector
	, otherContactHistoryReady
	, otherContactsSelector
	, linkErrandMyErrandsSelector
	, linkedErrandsSelector
	, myErrandsWithoutLinked
	, getSelectedLinkedErrandsAddToReply
} from '../redux/selectors/errand';
import {
	acquireOperations,
	showHideOtherContactErrand,
	showHideAcquireErrand,
	acquireAllOperations,
	linkErrandMyErrandAllOperations,
	linkedErrandAllOperations,
	acquireDeselectAllOperations,
	acquireMoreOperations,
	expandHideOption,
	moveLinkedToAssociate
} from '../redux/actions/errand';
import {
	acquireAllOtherErrands,
	acquireOneOtherErrand,
	acquireOtherErrands,
	addAgentWorkingOnErrand,
	fbEmotionHistory,	
	fetchPrintErrand,
	historyByErrandAndThread,
	historyErrandContacts,
	loadMoreOtherContacts,
	openOtherContactErrand,
	reloadBasicErrand,
	removeAgentWorkingOnErrand,
	showOtherContactErrand,
	acquireLinkErrand
} from '../redux/actions/async/errand';
import { popErrorOnly , togglePopAlert, toggleToastAlert} from '../redux/actions/hmf';
import { switchReviewErrandURL } from '../redux/actions/async/review';
import {
	agentSetErrandMsgTruncate,
	errandPreview,
	isErrandPreviewInProgress
} from '../redux/actions/async/workflow';
import { translationHandler } from './chat';

import {
	checkIfAllSelectedCustomized
} from '../redux/util';
import { TOAST_TYPE, TOAST_POSITION } from "../reactcomponents/Modal";

const noShowUI = {showArea: false, showTags: false};

const OtherContactErrandBoxHeaderInfo = ({ currentContext, ...props }) => (
	<ErrandBoxHeaderInfo {...props}
		allTags={emptyArray}
		attachmentNotReady={false}
		chat={null}
		// NOTE: this is not used for other contact errand
		currentAgentStatus=""
		currentArea={props.errand.area}
		// NOTE: this is the best status to get the proper status of an errand
		// open from other contact.
		currentContext={CTX_SEARCH}
		hasAgentAttached={false}
		isChat={false}
		isCollaboration={false}
		showAttachment={false}
		style={emptyObject}
		tags={emptyArray}
		toggleAttachBox={false} // does not seem to be used now
		ui={noShowUI}
		toggleableErrandLink={false}
	/>
);

const mapInfoState = (state, props) => {
	const { errand, workflow } = state.app
		, {
			activeUserId
			, areas
			, doneDateFeature
			, "pickUpNext": autoPickupFeature
		} = workflow.fetchWfSettings.data
		;
	return {
		acquiredData: otherContactErrandAcquireDataSelector(state, props)
		, activeUserId
		, areas
		, attachmentSize: otherContactErrandIncomingAttachmentSizeSelector(state, props)
		, autoPickupFeature
		, currentContext: contextMemo(state)
		, currentDueDate: "" // TODO: no good endpoint to get this value
		, doneDateFeature
		, errand: otherContactBasicErrandSelector(state, props)
		, owner: otherContactErrandOwnerSelector(state, props)
		, selectedTags: otherContactErrandTagsSelector(state, props)
		, totalAttachmentsSize: otherContactErrandAttachmentSizeSelector(state, props)
		, Workflow: state.server.services.constants.Workflow
		, forwardAreaInOrg: false
		, myErrandsNorm: errand.myErrands.data.norm
	};
};

const OtherContactErrandBoxHeaderInfoCtnr = connect(mapInfoState)(OtherContactErrandBoxHeaderInfo);

const Dummy = () => null;

const UserInfoComponent = withUserInfo(Dummy);

let	InfoComponent = OtherContactErrandBoxHeaderInfoCtnr;
let	InfoComponent2 = UserInfoComponent;

function withErrandBoxHeaderBase(Component) {
	return withErrandBoxHeader(Component, InfoComponent);
}

const ErrandBoxHeader = compose(
	withErrandBoxHeaderBase
	, withHeaderLeftBox
)(InfoComponent2);

const OtherContactErrandBoxHeader = props => (
	<ErrandBoxHeader {...props}
		id="other-contact-errand-box-header"
		chat={false} // open other contact should never able open a live chat
		collaborationList={emptyArray}
		currentReply={RPLY_ERRAND}
		// Assume that one errand has one sender, so theoritically communication
		// is one to one!
		customers={1}
		extendedData={emptyObject}
		internalComments={0}

		archiveAttachments={emptyArray}
		collaborateAnsAttachments={emptyArray}
		libraryAttachments={emptyArray}
		questionAttachments={emptyArray}
		savedAttachments={emptyArray}
		uploadedAttachments={emptyArray}

		attachmentList={emptyArray}
		attachmentSize={0}
		notesAttachmentList={emptyArray}
		selectedList={emptyObject}

		csvFilePreview=""
		textFilePreview=""
	/>
);

const mapHeaderState = (state, props) => {
	const { services, constants } = state.server
		, { Workflow } = constants
		;
	return {
		agents: otherContactErrandHistoryAgentsSelector(state, props)
		, client: otherContactErrandClientSelector(state, props)
		, errand: otherContactBasicErrandSelector(state, props)
		, history: otherContactErrandHistorySelector(state, props)
		, serverServices: services
		, totalAttachmentsSize: otherContactErrandIncomingAttachmentSizeSelector(state, props)
		, Workflow
	};
};

const ErrandBoxHeaderCtnr = connect(mapHeaderState)(OtherContactErrandBoxHeader);

const mapState = (state, props) => {
	const { acquireOpr } = state.app.errand
		, { showId, showThreadId, showPopup: show } = acquireOpr
		, {
			translation: canTranslate
			, interface: interfaceLang
		} = workflowSettingsSelector(state)
		, { activeMainMenu } = state.app.menu.mainMenu
		;
	return {
		canAcquire: canAcquireOtherContactErrandSelector(state, props)
		, canTranslate
		, constants: state.server.constants
		, data: otherContactErrandSelector(state)
		, sumHistory: state.app.errand.sumHistory
		, eid: getCurrentErrand(state)
		, showId
		, showThreadId
		, historyReady: otherContactHistoryReady(state)
		, interfaceLang
		, show
		, mobile: mobileStateSelector(state, props)
		, mainMenu : activeMainMenu
	};
};

const mapDispatch = dispatch => {
	return {
		onAcquire: (errandId, threadId) => {
			// console.log("dbg: acquire-button:", {errandId, threadId});
			dispatch(acquireOneOtherErrand(errandId, threadId));
		}
		, onActionClick: () => {
		}
		, onClose: () => {
			dispatch(showHideOtherContactErrand(false));
		}
		, onTranslation: (...args) => translationHandler(dispatch, ...args)
	};
};

const OtherContactErrand = withOtherContactErrand(ErrandBoxHeaderCtnr);

const OCECtnr = connect(mapState, mapDispatch)(OtherContactErrand);

export default OCECtnr;

const getSelectedErrandId = (opr) => {
	let selectedIds = [];
	$.each(opr, function(k, v) {
		if (v.selected) {
			selectedIds.push(k);
		}
	});
	return selectedIds;
}

const getSelectedErrandDisplayId = (opr,norm) => {
	let selectedDisplayIds = [];
	$.each(opr, function(k, v) {
		if (v.selected) {
			selectedDisplayIds.push(norm[v].displayId);
		}
	});
	return selectedDisplayIds;
}

const mapAcquireErrands = (state, props) => {
	const errands = state.domain[D_BASIC_ERRANDS].byId
		, e = state.app.errand
		, ui = e.ui.reply
		, isOpenErrand = ui.isOpenErrand
		, currentErrand = getCurrentErrand(state)
		, linkErrandMyErrands = myErrandsWithoutLinked(state) // errands in MyErrands that not linked
		, linkedErrands = linkedErrandsSelector(state, props) // errands in MyErrands that linked with currentErrand
		, myErrandAllSelected = checkIfAllSelectedCustomized(linkErrandMyErrands.opr)  // indicates all linkErrandMyErrands selected
		, selectedLinkErrandMyErrands = getSelectedErrandId(linkErrandMyErrands.opr) // linkErrandMyErrands selected to be linked
		, linkedErrandAllSelected = checkIfAllSelectedCustomized(linkedErrands.opr) // indicates all linkedErrands selected
		, selectedLinkedErrands = getSelectedErrandId(linkedErrands.opr) // linkedErrands selected to be unlinked
		, selectedLinkedErrandsAddToReply = getSelectedLinkedErrandsAddToReply(state) // errands that will add to reply when Add To Reply button
		, selectedLinkedErrandsAddToReplyId = selectedLinkedErrandsAddToReply.addToReplyId
		, selectedLinkedErrandsAddToReplyDisplayId = selectedLinkedErrandsAddToReply.addToReplyDisplayId;
return {
		currentContext: contextMemo(state)
		, currentErrand
		, ocId: e.acquireOpr.ocId
		, showPopup: e.acquireOpr.showPopup
		, show: e.ui.acquireErrand.show
		, chat: e.chat
		, preview: state.domain.previewData.byId
		, previewWip: isErrandPreviewInProgress(state)
		, myAgentId: getMyId(state, props)
		, noMore: e.contacts.data.noMore
		, noMoreHistory: e.historyContacts.data.noMore
		, tab: currentAcquireTabSelector(state, props)
		, isOpenErrand
		, errands
		, histories: historyContactsSelector(state, props)
		, others: otherContactsSelector(state, props)
		, buttons: acquireButtonsSelector(state, props)
		, acquire: acquireErrandsSelector(state, props)
		, acquireErrandAllSelected: e.contacts.data.allSelected
		, openType : ui.openType
		, linkErrandMyErrands
		, linkedErrands
		, myErrandAllSelected
		, selectedLinkErrandMyErrands
		, linkedErrandAllSelected
		, selectedLinkedErrands
		, selectedLinkedErrandsAddToReplyId
		, selectedLinkedErrandsAddToReplyDisplayId
	};
};

export function toggleAcquireErrand(dispatch, from, id, select) {
	let sel;
	if (from === ACQ_ERD_OTHER) {
		sel = SEL_ACT_ACQ_ERD_OTHER;
	} else if (from === ACQ_ERD_SELECTED_TOP) {
		sel = SEL_ACT_ACQ_SELECT;
	} else if (from === ACQ_ERD_SELECTED_BOTTOM) {
		sel = SEL_ACT_OTHER_CONTACT;
	} else if (from === ACQ_ERD_MY) {
		sel = SEL_ACT_MY_ERD;
	} else if (from === ACQ_ERD_LINKED) {
		sel = SEL_ACT_LINKED_ERD;
	}
	if (sel) {
		dispatch(acquireOperations(sel, {id, select}));
		if (sel != SEL_ACT_ACQ_ERD_OTHER) {
			if (select) {
				dispatch(addAgentWorkingOnErrand(id))
			} else {
				dispatch(removeAgentWorkingOnErrand(id))
			}
		}
	}
}

const mapAcquireErrandsCallbacks = dispatch => {
	return {
		onAcquireErrands: () => {
			dispatch(acquireOtherErrands())
			.then(res => {
				dispatch(showHideAcquireErrand(false));
			});
		}
		, onLinkErrands: (ids, currentErrand) => {
			dispatch(acquireLinkErrand(ids, currentErrand, "link", false))
			//.then(res => {
			//	dispatch(showHideAcquireErrand(false));
			//});
		}
		, onUnlinkErrands: (ids, currentErrand, allSelected) => {
			dispatch(acquireLinkErrand(ids,currentErrand,"unlink", allSelected))
			/*.then(res => {
				dispatch(showHideAcquireErrand(false));
			}); */
		}
		, onAddLinkedToReply: (ids, displayId, currentErrand) => {
			var tmpIds = [];
			Object.entries(ids).forEach(([key, val]) => tmpIds.push(parseInt(val)));

			if (ids.length> 0) {
				dispatch(moveLinkedToAssociate(tmpIds, false, false))
			}
			// dispatch(togglePopAlert("Errands " + ids.join() + " added to reply"));
			dispatch(toggleToastAlert(TOAST_TYPE.success, TOAST_POSITION.topRight, "Errands " + displayId.join() + " added to reply"));
	}
		, onAcquireAllErrands: () => {
			dispatch(acquireAllOtherErrands());
		}
		, onLoadMoreAcquireErrands: (id, offset) => {
			return dispatch(loadMoreOtherContacts(id, offset))
			.then(res => {
					dispatch(acquireMoreOperations());
				});
		}
		, onLoadMoreHistories: (id, offset) => {
			if (process.env.NODE_ENV !== 'production') {
				console.log("dbg: load more other histories.", {offset});
			}
			return dispatch(historyErrandContacts(id, offset));
		}
		, onShowOtherContactErrand: (id, chat, ce, oc, data) => {
			dispatch(showOtherContactErrand(id, data.threadId));
		}
		, onOpenErrand: (id, chat, ce, oc) => {
			// WARNING: chat argument here is currently opened errand whether is
			// chat errand or not, it is NOT indication of whether id is chat
			// errand or not. ce is current opened errand ID. oc is current
			// other contact ID. id is the ID of the errand that need to be
			// opened.
			if (process.env.NODE_ENV !== 'production') {
				console.log("dbg: from acquire-errands: ", {id, chat, ce, oc});
			}
			dispatch(openOtherContactErrand(id, chat, ce, oc));
		}
		, onOpenErrandWhenReview: (id, chat, previousCurrent, oc, errand) => {
			if (errand && errand.data && errand.data.cipherKey) {
				return dispatch(switchReviewErrandURL({
					id
					, cipherKey: errand.data.cipherKey
					, previousCurrent
				}));
			} else {
				console.log("invalid errand data, can't switch:", errand);
			}
		}
		, onPreview: id => {
			dispatch(errandPreview(id));
		}
		, onReloadErrand: id => {
			dispatch(reloadBasicErrand(id));
		}
		, onSwitchAcquire: isOpenErrand => {
			dispatch(acquireOperations('switch', isOpenErrand));
		}
		, onSwitchOpenType: isOpenType => {
			dispatch(acquireOperations('switchOpenType', isOpenType));
		}
		, onSelectAcquireErrand: (from, id, select) => {
			toggleAcquireErrand(dispatch, from, id, select);
			if(!select) {
				dispatch(acquireDeselectAllOperations(false));
			}
		}
		, onSelectLinkErrandMyErrand: (from, id, select) => {
			toggleAcquireErrand(dispatch, from, id, select);
		}
		, onSelectLinkedErrand: (from, id, select) => {
			toggleAcquireErrand(dispatch, from, id, select);
		}
		, onToggle: tgl => {
			dispatch(acquireAllOperations(false));
			dispatch(linkErrandMyErrandAllOperations(false));
			dispatch(linkedErrandAllOperations(false));
			dispatch(showHideAcquireErrand(tgl));
		}
		, onSelectAllAcquireErrand: selectAll => {
			dispatch(acquireAllOperations(selectAll));
		}
		, onSelectAllLinkErrandMyErrand: selectAll => {
			dispatch(linkErrandMyErrandAllOperations(selectAll));
		}
		, onSelectAllLinkedErrand: selectAll => {
			dispatch(linkedErrandAllOperations(selectAll));
		}
	};
};

export const withAcquireErrands = connect(
	mapAcquireErrands,
	mapAcquireErrandsCallbacks
)

export const withAssociatedErrands = connect(
	state => ({ associatedErrands: getMemoizeAssociatedObject(state) }),
	dispatch => ({
		onClickInfo: (action, source, errandID) => {
			if (action === 'delete') {
				toggleAcquireErrand(dispatch, source, errandID, false);
			}
		},
		onOpenAcquiredErrand: (id, threadId) => {
			dispatch(showOtherContactErrand(id, threadId))
		}
	})
)

export const withOtherContactErrandHandlers = connect(
	state => ({
		data: getHistoriesAndMy(state),
		truncateErrandMessageByDefault: messageTruncateByDefault(state)
	}),
	dispatch => ({
		onFetchHistory: (errandId, threadId) => dispatch(historyByErrandAndThread(
			errandId,
			threadId
		)),
		onPopAlert: (...args) => dispatch(popErrorOnly(...args)),
		onPrint: (doPrint, errandId, withHistory) => dispatch(fetchPrintErrand(
			errandId,
			doPrint,
			withHistory
		)),
		onSetErrandMessageTruncatePref: errandMsgTruncate => dispatch(agentSetErrandMsgTruncate({ errandMsgTruncate })),
		onSocialMediaUIAction: (...args) => dispatch(fbEmotionHistory(...args)),
		onClickAgentAssistTrigger: (toggle) => {
			dispatch(expandHideOption('AIAnswerPanel', 0, toggle));
		},
	})
)
